// import React, { useState } from 'react';
// import Navbar from '../components/Navbar';
// import ReactSlider from 'react-slider';

// const HotelListing = () => {

//   const [priceRange, setPriceRange] = useState([500, 10000]);


//   const handlePriceChange = (values) => {
//     setPriceRange(values);
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container pt-4 mt-2">
//         <div className="list-heading">
//           <div className="sort-content">
//             <h3>Best Hotels to stay in Sydney, Australia</h3>
//             <h6>200+ Hotels Found in Sydney, Australia</h6>
//           </div>
//           <div className="sort-select">
//             <h6>Sort by:</h6>
//             <div>
//               <select className="form-select" aria-label="Default select example">
//                 <option defaultValue="Popularity">Popularity</option>
//                 <option value="1">Rating</option>
//                 <option value="2">Star</option>
//                 <option value="3">View</option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className="row pt-4">
//           <div className="col-lg-3">
//             <div className="filter-column">
//               <div className="availability-filter">
//                 <h5>Availability</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="mb-3">
//                     <label htmlFor="">Destination</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Sydney, Australia"
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">From</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">To</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="avail-btn">
//                     <button>Check Availability</button>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Price</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <label>
//                     <span>AUD {priceRange[0]}</span> - <span>AUD {priceRange[1]}</span>
//                   </label>
//                   <div className="range-input">
//                     <ReactSlider
//                       className="horizontal-slider"
//                       thumbClassName="thumb"
//                       trackClassName="track"
//                       min={500}
//                       max={10000}
//                       value={priceRange}
//                       onChange={handlePriceChange}
//                       minDistance={100}
//                     />
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Type</h5>
//                 <hr />
//                 <form className="filter-form">
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Apartments
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//    Hotels
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Villas
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Hostels
//   </label>
// </div>
// <span>(123)</span>
// </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Ratings</h5>
//                 <hr />
//                 <form className="filter-form">
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     2 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//    3 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     4 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     5 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
//                 </form>
//               </div>

//             </div>
//           </div>

// <div className="col-lg-9 pt-4">
//   <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />
// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel2.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// </div>

//         </div>
//       </div>
//     </>
//   );
// };

// export default HotelListing;


// import React, { useState } from 'react';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import ReactSlider from 'react-slider';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from "../AuthContext";

// const HotelListing = () => {

//   const [priceRange, setPriceRange] = useState([0, 10000]);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { apipath } = useAuth();
//   const searchResults = location.state?.searchResults || [];
//   const [searchQuery, setSearchQuery] = useState({
//     cityName: location.state?.searchQuery?.cityName || '',
//     countryName: location.state?.searchQuery?.countryName || '',
//     checkInDate: location.state?.searchQuery?.checkInDate || '',
//     checkOutDate: location.state?.searchQuery?.checkOutDate || ''
//   });
//   const [loading, setLoading] = useState(false);

//   const [propertyTypes, setPropertyTypes] = useState({
//     apartments: false,
//     hotels: false,
//     villas: false,
//     hostels: false,
//   });

//   const [propertyTypeCounts, setPropertyTypeCounts] = useState({});

//   const [ratings, setRatings] = useState({
//     2: false,
//     3: false,
//     4: false,
//     5: false,
//   });

//   useEffect(() => {
//     // Count the occurrences of each property type in searchResults
//     const counts = searchResults.reduce((acc, hotel) => {
//       const type = hotel.propertyType?.toLowerCase(); // Ensure it's in lowercase to match the filter keys
//       if (type) {
//         acc[type] = (acc[type] || 0) + 1;
//       }
//       return acc;
//     }, {});

//     setPropertyTypeCounts(counts);
//   }, [searchResults]);

//   const handlePriceChange = (values) => {
//     setPriceRange(values);
//   };

//   const handlePropertyTypeChange = (type) => {
//     setPropertyTypes(prev => ({ ...prev, [type]: !prev[type] }));
//   };

//   const handleRatingChange = (rating) => {
//     setRatings(prev => ({ ...prev, [rating]: !prev[rating] }));
//   };

//   // Calculate counts for each star rating
//   const starCounts = { 2: 0, 3: 0, 3.5: 0, 4: 0, 4.5: 0, 5: 0 };
//   searchResults.forEach(hotel => {
//     const rating = hotel.hotelRating;
//     if (rating >= 2 && rating <= 5) {
//       starCounts[rating]++;
//     }
//   });

//   // Filter function
//   const filteredResults = searchResults.filter(hotel => {
//     const hotelPrice = hotel.total || 0;
//     const hotelRating = hotel.hotelRating || 0;
//     const hotelType = hotel.propertyType?.toLowerCase() || '';

//     const withinPriceRange = hotelPrice >= priceRange[0] && hotelPrice <= priceRange[1];
//     const matchesPropertyType = propertyTypes[hotelType] || Object.values(propertyTypes).every(val => !val);
//     const matchesRating = ratings[hotelRating] || Object.values(ratings).every(val => !val);

//     return withinPriceRange && matchesPropertyType && matchesRating;
//   });

//   return (
//     <>
//       <Navbar />
//       <div className="container pt-4 mt-2">
//         <div className="list-heading">
//           <div className="sort-content">
//             <h3>Best Hotels to stay search results on Destinique International</h3>
//             <h6>{searchResults.length} Hotels Found in {searchQuery.cityName}, {searchQuery.countryName}</h6>
//           </div>
//           <div className="sort-select">
//             <h6>Sort by:</h6>
//             <div>
//               <select className="form-select" aria-label="Default select example">
//                 <option defaultValue="Popularity">Popularity</option>
//                 <option value="1">Rating</option>
//                 <option value="2">Star</option>
//                 <option value="3">View</option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className="row pt-4">
//           <div className="col-lg-3">
//             <div className="filter-column">
//               <div className="availability-filter">
//                 <h5>Availability</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="mb-3">
//                     <label htmlFor="">Destination</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Sydney, Australia"
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">From</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">To</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="avail-btn">
//                     <button>Check Availability</button>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Price</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <label>
//                     <span>A$ {priceRange[0]}</span> - <span>A$ {priceRange[1]}</span>
//                   </label>
//                   <div className="range-input">
//                     <ReactSlider
//                       className="horizontal-slider"
//                       thumbClassName="thumb"
//                       trackClassName="track"
//                       min={0}
//                       max={10000}
//                       value={priceRange}
//                       onChange={handlePriceChange}
//                       minDistance={100}
//                     />
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Type</h5>
//                 <hr />
//                 <form className="filter-form">
//                   {Object.keys(propertyTypes).map(type => (
//                     <div className="check-filter" key={type}>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           checked={propertyTypes[type]}
//                           onChange={() => handlePropertyTypeChange(type)}
//                         />
//                         <label className="form-check-label">
//                           {type.charAt(0).toUpperCase() + type.slice(1)}
//                         </label>
//                       </div>
//                       <span>(123)</span>
//                     </div>
//                   ))}
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Ratings</h5>
//                 <hr />
//                 <form className="filter-form">
//                   {Object.keys(starCounts).map(rating => (
//                     <div className="check-filter" key={rating}>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           checked={ratings[rating]}
//                           onChange={() => handleRatingChange(rating)}
//                         />
//                         <label className="form-check-label">
//                           {rating} Star
//                         </label>
//                       </div>
//                       <span>{starCounts[rating] || 0}</span>
//                     </div>
//                   ))}
//                 </form>
//               </div>

//             </div>
//           </div>

//           <div className="col-lg-9 pt-4">
//             {filteredResults.length > 0 ? (
//               filteredResults.map((hotel) => (
//                 <div key={hotel.hotelId}>
//                   <div className="row hotel-list-card">
//                     <div className="col-lg-4 hotel-img-box">
//                       <img src={hotel.thumbNailUrl ||"assets-travel/listing-hotel.png"} alt="" />
//                     </div>
//                     <div className="col-lg-8 hotel-list-content">
//                       <div className="content-heading">
//                         <div>
//                           <h6>{hotel.hotelName}</h6>
//                         </div>
//                         <div>
//                           <i class="bi bi-heart"></i>
//                         </div>
//                       </div>

//                       <div className="amenities-list">
//                       <p>Property Type: {hotel.propertyType || "N/A"}</p>
//                       </div>

//                       <div className="amenities-list">
//                       <p>
//                           {Array.isArray(hotel.facilities)
//                             ? hotel.facilities.slice(0, 6).map(facilities => facilities).join(' · ')
//                             : 'No amenities listed'}{' '}{' '}...more
//                         </p>
//                         {/* <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//                         <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p> */}
//                       </div>

//                       <div className="rating-book">
//                         <div className="rating-review-list">
//                           <h6>{hotel.hotelRating || "N/A"}<span><i class="bi bi-star-fill"></i></span> 
//                           {/* <small>(318 reviews)</small> */}
//                           </h6>
//                         </div>
//                         <div className='book-list'>
//                           {/* <p>1 night, 2adults</p> */}
//                           <h5>A$ {hotel.total || "N/A"}</h5>
//                           <button>View Details</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <hr />
//                 </div>
//               ))
//             ) : (
//               <p>No hotels found.</p>
//             )}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default HotelListing;



import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ReactSlider from 'react-slider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthContext";

const HotelListing = () => {

  const [priceRange, setPriceRange] = useState([0, 10000]);
  const location = useLocation();
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const searchResults = location.state?.searchResults || [];
  const [searchQuery, setSearchQuery] = useState({
    cityName: location.state?.searchQuery?.cityName || '',
    countryName: location.state?.searchQuery?.countryName || '',
    checkInDate: location.state?.searchQuery?.checkInDate || '',
    checkOutDate: location.state?.searchQuery?.checkOutDate || ''
  });
  const [loading, setLoading] = useState(false);

  const [propertyTypes, setPropertyTypes] = useState({});
  const [propertyTypeCounts, setPropertyTypeCounts] = useState({});

  const [ratings, setRatings] = useState({});
  const [ratingCounts, setRatingCounts] = useState({});

  // Extract unique property types and counts
  useEffect(() => {
    const typeCounts = {};

    searchResults.forEach(hotel => {
      const type = hotel.propertyType?.toLowerCase() || 'unknown';
      if (typeCounts[type]) {
        typeCounts[type]++;
      } else {
        typeCounts[type] = 1;
      }
    });

    setPropertyTypes(Object.keys(typeCounts).reduce((acc, type) => {
      acc[type] = false; // Initialize all property types to unchecked (false)
      return acc;
    }, {}));

    setPropertyTypeCounts(typeCounts); // Set the count of each property type
  }, [searchResults]);

  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  const handlePropertyTypeChange = (type) => {
    setPropertyTypes(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const handleRatingChange = (rating) => {
    setRatings(prev => ({ ...prev, [rating]: !prev[rating] }));
  };

  // Calculate counts for each star rating
  const starCounts = { 2: 0, 3: 0, 3.5: 0, 4: 0, 4.5: 0, 5: 0 };
  searchResults.forEach(hotel => {
    const rating = hotel.hotelRating;
    if (rating >= 2 && rating <= 5) {
      starCounts[rating]++;
    }
  });

  // Extract unique ratings and counts
  useEffect(() => {
    const ratingCount = {};

    searchResults.forEach(hotel => {
      const rating = Math.floor(hotel.hotelRating || 0); // Round down to nearest whole number
      if (rating >= 2 && rating <= 5) { // Consider ratings between 2 and 5 stars
        if (ratingCount[rating]) {
          ratingCount[rating]++;
        } else {
          ratingCount[rating] = 1;
        }
      }
    });

    setRatings(Object.keys(ratingCount).reduce((acc, rating) => {
      acc[rating] = false; // Initialize all ratings to unchecked (false)
      return acc;
    }, {}));

    setRatingCounts(ratingCount); // Set the count of each rating
  }, [searchResults]);

  // Filter function
  const filteredResults = searchResults.filter(hotel => {
    const hotelPrice = hotel.total || 0;
    const hotelRating = hotel.hotelRating || 0;
    const hotelType = hotel.propertyType?.toLowerCase() || ''; // Ensure the property type is in lowercase

    const withinPriceRange = hotelPrice >= priceRange[0] && hotelPrice <= priceRange[1];
    const matchesPropertyType = propertyTypes[hotelType] || Object.values(propertyTypes).every(val => !val);
    const matchesRating = ratings[hotelRating] || Object.values(ratings).every(val => !val);

    return withinPriceRange && matchesPropertyType && matchesRating;
  });

  return (
    <>
      <Navbar />
      <div className="container pt-4 mt-2">
        <div className="list-heading">
          <div className="sort-content">
            <h3>Best Hotels to stay search results on Destinique International</h3>
            <h6>{searchResults.length} Hotels Found in {searchQuery.cityName}, {searchQuery.countryName}</h6>
          </div>
          <div className="sort-select">
            <h6>Sort by:</h6>
            <div>
              <select className="form-select" aria-label="Default select example">
                <option defaultValue="Popularity">Popularity</option>
                <option value="1">Rating</option>
                <option value="2">Star</option>
                <option value="3">View</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-3">
            <div className="filter-column">
              <div className="availability-filter">
                <h5>Availability</h5>
                <hr />
                <form className="filter-form">
                  <div className="mb-3">
                    <label htmlFor="">Destination</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sydney, Australia"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">From</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">To</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="avail-btn">
                    <button>Check Availability</button>
                  </div>
                </form>
              </div>

              <div className="availability-filter">
                <h5>Price</h5>
                <hr />
                <form className="filter-form">
                  <label>
                    <span>A$ {priceRange[0]}</span> - <span>A$ {priceRange[1]}</span>
                  </label>
                  <div className="range-input">
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      min={0}
                      max={10000}
                      value={priceRange}
                      onChange={handlePriceChange}
                      minDistance={100}
                    />
                  </div>
                </form>
              </div>

              {/* Render checkboxes dynamically */}
              <div className="availability-filter">
                <h5>Property Type</h5>
                <hr />
                <form className="filter-form">
                  {Object.keys(propertyTypes).map(type => (
                    <div className="check-filter" key={type}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={propertyTypes[type]}
                          onChange={() => handlePropertyTypeChange(type)}
                        />
                        <label className="form-check-label">
                          {type.charAt(0).toUpperCase() + type.slice(1)} {/* Capitalize the first letter */}
                        </label>
                      </div>
                      <span>({propertyTypeCounts[type] || 0})</span> {/* Display the count */}
                    </div>
                  ))}
                </form>
              </div>

              {/* Render checkboxes dynamically for ratings */}
              <div className="availability-filter">
                <h5>Property Ratings</h5>
                <hr />
                <form className="filter-form">
                  {Object.keys(ratings).map(rating => (
                    <div className="check-filter" key={rating}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={ratings[rating]}
                          onChange={() => handleRatingChange(rating)}
                        />
                        <label className="form-check-label">
                          {rating} Star
                        </label>
                      </div>
                      <span>({ratingCounts[rating] || 0})</span> {/* Display the count */}
                    </div>
                  ))}
                </form>
              </div>

            </div>
          </div>

          <div className="col-lg-9 pt-4">
            {filteredResults.length > 0 ? (
              filteredResults.map((hotel) => (
                <div key={hotel.hotelId}>
                  <div className="row hotel-list-card">
                    <div className="col-lg-4 hotel-img-box">
                      <img src={hotel.thumbNailUrl || "assets-travel/listing-hotel.png"} alt="" />
                    </div>
                    <div className="col-lg-8 hotel-list-content">
                      <div className="content-heading">
                        <div>
                          <h6>{hotel.hotelName}</h6>
                        </div>
                        <div>
                          <i class="bi bi-heart"></i>
                        </div>
                      </div>

                      <div className="amenities-list">
                        <p>Property Type: {hotel.propertyType || "N/A"}</p>
                      </div>

                      <div className="amenities-list">
                        <p>
                          {Array.isArray(hotel.facilities)
                            ? hotel.facilities.slice(0, 6).map(facilities => facilities).join(' · ')
                            : 'No amenities listed'}{' '}{' '}...more
                        </p>
                      </div>

                      <div className="rating-book">
                        <div className="rating-review-list">
                          <h6>{hotel.hotelRating || "N/A"}<span><i class="bi bi-star-fill"></i></span>
                          </h6>
                        </div>
                        <div className='book-list'>
                          <h5>A$ {hotel.total || "N/A"}</h5>
                          <button>View Details</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <p>No hotels found.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HotelListing;