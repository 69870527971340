import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Carousel } from "react-bootstrap";
import ExampleCarouselImage from "../components/ExampleCarouselImage";
import { useAuth } from "../AuthContext";


const Landing = () => {
  const { apipath } = useAuth();
  const [landingPageContent, setLandingPageContent] = useState({
    Top_Destination: [],
    Flight_Offer: [],
    Hotel_Offer: [],
    Car_Booking_Offer: [],
    Travel_By_Activity_Event: [],
    Travel_By_Activity_Card: [],
    Advertise_card1: [],
    Stories: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${apipath}/LandingPage-content/`)
      .then(response => {
        const {
          Top_DestinationObj,
          Flight_OfferObj,
          Hotel_OfferObj,
          Car_Booking_OfferObj,
          Travel_By_Activity_EventObj,
          Travel_By_Activity_CardObj,
          Advertise_card1Obj,
          StoriesObj,
        } = response.data;

        setLandingPageContent({
          Top_Destination: Top_DestinationObj,
          Flight_Offer: Flight_OfferObj,
          Hotel_Offer: Hotel_OfferObj,
          Car_Booking_Offer: Car_Booking_OfferObj,
          Travel_By_Activity_Event: Travel_By_Activity_EventObj,
          Travel_By_Activity_Card: Travel_By_Activity_CardObj,
          Advertise_card1: Advertise_card1Obj,
          Stories: StoriesObj,
        });

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, [apipath]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

  // const [ bannerTab, setBannerTab ] = useState("hotel");

  // const handleBannerTab = (tabName) => {
  //   setBannerTab(tabName)
  // };

  return (
    <>
      <Navbar />
      <Carousel interval={null}>
        <Carousel.Item>
          <div className="banner-video-bg">
            <video
              src="assets-travel/new-banner1.mp4"
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: '100vh',
                objectFit: 'cover',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                zIndex: 99,
              }}
            >
              <ExampleCarouselImage />
            </div>
          </div>
          <Carousel.Caption>
            {/* Your caption content */}
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>

      {/* TOP DESTINATIONS */}

      <div className="container top">
        <div className="content-header">
          <h1>Stay in Top Vacation Destinations</h1>
        </div>
        <div className="row dest-row">
          {landingPageContent.Top_Destination.map((item) => (
            <div className="col-lg-3">
              <div className="dest-card">
                <img src={item.image} alt="" />
                <div className="dest-title">
                  <h2>{item.Destination_name}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* TOP OFFERS */}

      <div className="container top">
        <div className="content-header">
          <h1>Offers</h1>
        </div>
        <div className="row dest-row">
          {landingPageContent.Flight_Offer.map((item) => (
            <div className="col-lg-6 px-4">
              <div className="row offer-row">
                <div className="col-lg-6">
                  <div className="offer-img">
                    <img src={item.flight_image} alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="offer-right">
                    <h6>{item.flight_travel_type}</h6>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.catchline }}
                    />
                    <p>
                      {item.offered_dealprice}
                    </p>
                    <div>
                      <button>Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* TOP HOTELS */}

      <div className="container top">
        <div className="content-header">
          <h1>Top Rated Hotels</h1>
          <p>Quality as judged by customers. Book at the ideal price!</p>
        </div>
        <div className="row dest-row">
          {landingPageContent.Hotel_Offer.map((item) => (
            <div className="col-lg-4">
              <div className="hotel-card">
                <div className="hotel-img">
                  <img src={item.hotel_image} alt="" />
                  <div className="like">
                    <i className="bi bi-heart"></i>
                  </div>
                </div>
                <div className="hotel-content">
                  <div className="rating-container">
                    <span className="hotel-rating">
                      <i className="bi bi-star-fill"></i>{" "}
                      <span>
                        {item.hotel_rating}
                      </span>
                    </span>
                  </div>
                  <h2>{item.hotel_name}</h2>
                  <div className="hotel-location">
                    <span>
                      <i className="bi bi-geo-alt-fill"></i> {item.hotel_location}
                    </span>
                    <span>
                      <i className="bi bi-star-fill text-black"></i>
                      <i className="bi bi-star-fill text-black"></i>
                      <i className="bi bi-star-fill text-black"></i>
                      <i className="bi bi-star-fill text-black"></i>
                      <i className="bi bi-star-fill text-black"></i>
                    </span>
                  </div>
                  <div className="hotel-button">
                    <h5>
                      {item.hotel_price}
                    </h5>
                    <button>Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* TOP CAR BOOKINGS */}

      <div className="container top">
        <div className="content-header">
          <h1>Top Rated Car Booking</h1>
          <p>Quality as judged by customers. Book at the ideal price!</p>
        </div>
        <div className="row dest-row">
          {landingPageContent.Car_Booking_Offer.map((item) => (
            <div className="col-lg-4">
              <div className="hotel-card">
                <div className="hotel-img">
                  <img src={item.car_image} alt="" />
                  <div className="like">
                    <i className="bi bi-heart"></i>
                  </div>
                </div>
                <div className="hotel-content">
                  <div className="rating-container">
                    <span className="hotel-rating">
                      <i className="bi bi-star-fill"></i>{" "}
                      <span>
                        {item.car_rating}
                      </span>
                    </span>
                  </div>
                  <h2>{item.car_model_name}</h2>
                  <div className="hotel-location">
                    <span>
                      <i className="bi bi-geo-alt-fill"></i> {item.car_rent_location}
                    </span>
                  </div>
                  <div className="car-icons row">
                    <div className="col-md-6 car-col">
                      <div>
                        <img src="assets-travel/mile.svg" alt="" />{" "}
                        <span>{item.car_odometer_reading}</span>
                      </div>
                    </div>
                    <div className="col-md-6 car-col">
                      <div>
                        <img src="assets-travel/Clip path group.png" alt="" />{" "}
                        <span>{item.car_model_type}</span>
                      </div>
                    </div>
                    <div className="col-md-6 car-col">
                      <div>
                        <img src="assets-travel/fuel.png" alt="" />{" "}
                        <span>{item.car_fuel_type}</span>
                      </div>
                    </div>
                    <div className="col-md-6 car-col">
                      <div>
                        <img src="assets-travel/seat.png" alt="" />{" "}
                        <span>{item.car_seat_count}</span>
                      </div>
                    </div>
                  </div>
                  <div className="hotel-button car-button">
                    <h2>
                      {item.car_booking_price}
                    </h2>
                    <button>Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Travel By Activities */}

      <div className="container top">
        <div className="content-header activity-header">
          <h1>Travel By Activities</h1>
          <p>Navigate the Globe with Confidence</p>
        </div>
        <div className="row py-3">
          {landingPageContent.Travel_By_Activity_Event.map((item) => (
            <div className="col">
              <div className="activity-circle">
                <div className='activity-img'>
                  <img src={item.activity_image} alt="" />
                </div>
                <h5>{item.activity_name}</h5>
                <h6>{item.tour_count}</h6>
              </div>
            </div>
          ))}
        </div>

        <div className="row dest-row">
          {landingPageContent.Travel_By_Activity_Card.map((item) => (
            <div className="col-lg-4">
              <div className="hotel-card">
                <div className="hotel-img">
                  <img src={item.activity_card_image} alt="" />
                </div>
                <div className="hotel-content">
                  <div className="rating-container">
                    <span className="hotel-rating">
                      <img src="assets-travel/sign.png" alt="" />
                      <span>{item.activity_card_tour_count}</span>
                    </span>
                  </div>
                  <h2>{item.activity_card_name}</h2>

                  <div className="row exp-row">
                    <div className="col-lg-6">
                      <div dangerouslySetInnerHTML={{ __html: item.activity_list_col1 }} />
                    </div>
                    <div className="col-lg-6">
                      <div dangerouslySetInnerHTML={{ __html: item.activity_list_col2 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {landingPageContent.Advertise_card1.map((item) => (
            <div className="col-lg-4">
              <div className="ad-card">
                <div dangerouslySetInnerHTML={{ __html: item.advertise_card_catchline }} />
                <div className="my-4">
                  <button>
                    View More <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
                <div className="ad-img">
                  <img src={item.advertise_card_image} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* STORIES */}

      <div className="container story">
        {landingPageContent.Stories.map((item) => (
          <div className="row story-row">
            <div className="col-lg-6">
              <div className="story-card">
                <div className="story-img">
                  <img src={item.story1_image} alt="" />
                  <div className="story-content">
                    <div dangerouslySetInnerHTML={{ __html: item.story1_name }} />
                    <div className="story-button">
                      <button>
                        View More <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="story-card">
                <div className="story-img">
                  <img src={item.story2_image} alt="" />
                  <div className="story-content story-content-right">
                    <div dangerouslySetInnerHTML={{ __html: item.story2_name }} />
                    <div className="story-button">
                      <button>
                        View More <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="story-card">
                <div className="story-img">
                  <img src={item.story3_image} alt="" />
                  <div className="story-content story-3">
                    <div dangerouslySetInnerHTML={{ __html: item.story3_name }} />
                    <img src="assets-travel/img-explore.png" alt="" />
                    <div className="story-button story3-button">
                      <button>
                        View More <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="story-card">
                <div className="story-img">
                  <img src={item.story4_image} alt="" />
                  <div className="story-content">
                    <div dangerouslySetInnerHTML={{ __html: item.story4_name }} />
                    <div className="story-button story4-button">
                      <button>
                        View More <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="story-card">
                <div className="story-img">
                  <img src={item.story5_image} alt="" />
                  <div className="story-content story-5">
                    <div dangerouslySetInnerHTML={{ __html: item.story5_name }} />
                    <div className="story-button story5-button">
                      <button>
                        View More <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default Landing;
