import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useAuth } from "../AuthContext";

const Privacy = () => {
  const { apipath } = useAuth();
  const [PrivacyPage, setPrivacyPage] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    axios.get(`${apipath}/Privacy_and_terms-content/`)
      .then(response => {
        setPrivacyPage(response.data.PrivacyObj);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [apipath]);

  return (
    <>
      <Navbar />

      {PrivacyPage.map((item) => (
      <div className="container privacy">
      <div dangerouslySetInnerHTML={{ __html: item.privacy_content }}/>
      </div>
      ))}

      <Footer />
    </>
  );
};

export default Privacy;
